<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mb-5.lh-18.terms
		h1.mb-6 {{ $t('terms.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ updatedDate }}
		p.mb-4(v-html="$t('terms.head[0]')")
		p.mb-4(v-html="$t('terms.head[1]')")
		p.mb-4(v-html="$t('terms.head[2]')")
		p.mb-4(v-html="$t('terms.head[3]')")
		p.mb-4(v-html="$t('terms.head[4]')")

		hr.primary.my-6

		slot(v-for='(c,index) in common')
			h2.mb-6 {{index + 1}}. {{ c.title }}
			p(v-if='c.desc') {{ c.desc }}
			span.font-weight-medium(v-if='c.preText') {{ c.preText }}
			ol.ol-deep.ol-deep-parent.mb-4(:style="`--n: '${index+1}`")
				li(v-for='i in c.ul' :key='i.text' :class='{"d-block" : i.table || i.ulDeepSimple}')
					div(v-if='i.ulDeep')
						span(v-html='i.text')
						ol.ol-deep.mt-2(:style="`--n: '${index+1}`" v-if='i.ulDeep')
							li(v-for='d in i.ulDeep' :key='d.text')
								div(v-html='d.text')
					span(v-else-if='i.title')
						b {{ i.title }} –&nbsp;
						span(v-html='i.text')
					span(v-else v-html='i.text')

					ul.my-4.list-style-none(v-if='i.ulDeepSimple')
						li.mb-2(v-for='ii in i.ulDeepSimple' :key='ii.text')
							span(v-html='ii.text')
			span.font-weight-medium(v-if='c.afterText') {{ c.afterText }}
			ol.ol-deep.ol-deep-parent.mb-4(:style="`--n: '${index+1}`" v-if='c.ul1')
				li.invisible(style='height: 0')
				li(v-for='i in c.ul1' :key='i.text' :class='{"d-block" : i.table || i.ulDeepSimple}')
					div(v-if='i.ulDeep')
						span(v-html='i.text')
						ol.ol-deep.mt-2(:style="`--n: '${index+1}`" v-if='i.ulDeep')
							li(v-for='d in i.ulDeep' :key='d.text')
								div(v-html='d.text')
					span(v-else-if='i.title')
						b {{ i.title }} –&nbsp;
						span(v-html='i.text')
					span(v-else v-html='i.text')
					div.my-4(v-if='i.ulDeepSimple')
						p.mb-2(v-for='ii in i.ulDeepSimple' :key='ii.text')
							span(v-html='ii.text')
			p(v-if='c.desc1') {{ c.desc1 }}
			hr.primary.my-6

		pw-btn-up
</template>

<script>
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import moment from 'moment'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('h1.serviceRules2')}},

	components: {
		CompanyData,
		PwBtnUp
	},
	data() {
		let date = '2023-02-02';
		let day = moment(date).format('DD');

		return {
			updatedDate	: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
		}
	},
	computed: {
		addon () { return _.cloneDeep(bus.$t('terms.addon')) },
		addon1 () {
			let data = _.cloneDeep(bus.$t('terms.addon1'));

			_.each(data.list, (m, i) => {
				_.each(data.list[i].ul, (u, iu) => {
					data.list[i].ul[iu].text = data.list[i].ul[iu].text
						.replace(/\$\{bus\.botPayName\}/, bus.botPayName)
						.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName)
						.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
						.replace(/\$\{bus\.botLink\}/, bus.botLink)
						.replace(/\$\{bus\.botPay\}/, bus.botPay);

					_.each(data.list[i].ul[iu].ulDeep, (d, id) => {
						if ( data.list[i].ul[iu].ulDeep[id].text )
							data.list[i].ul[iu].ulDeep[id].text = data.list[i].ul[iu].ulDeep[id].text
								.replace(/\$\{bus\.botPayName\}/, bus.botPayName)
								.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName)
								.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
								.replace(/\$\{bus\.botLink\}/, bus.botLink)
								.replace(/\$\{bus\.botPay\}/, bus.botPay);
					})
				})
			})

			return data;
		},
		common () {
			let data = _.cloneDeep(bus.$t('terms.common'));

			_.each(data, (m, i) => {
				_.each(data[i].ul, (u, iu) => {
					data[i].ul[iu].text = data[i].ul[iu].text
						.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName)
						.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
						.replace(/\$\{bus\.botLink\}/, bus.botLink)
						.replace(/\$\{bus\.botPay\}/, bus.botPay);

					_.each(data[i].ul[iu].ulDeep, (d, id) => {
						if ( data[i].ul[iu].ulDeep[id].text )
							data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text
								.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName)
								.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
								.replace(/\$\{bus\.botLink\}/, bus.botLink)
								.replace(/\$\{bus\.botPay\}/, bus.botPay);
					})
				})

				_.each(data[i].ul1, (u, iu) => {
					data[i].ul1[iu].text = data[i].ul1[iu].text
						.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName)
						.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
						.replace(/\$\{bus\.botLink\}/, bus.botLink)
						.replace(/\$\{bus\.botPay\}/, bus.botPay);
				})
			})

			return data;
		}
	},
	created () {
		window.scrollTo(0,0);
	}
}
</script>

<style lang="scss" scoped>
.terms {
	margin-top: 76px;

	@media(max-width: 767px) {
		margin-top: 20px;
	}
}
</style>
